$("[data-scroll-top]").click(function(event) {
	event.preventDefault();

	var alvo = $(this).attr('href'),
		top = $(alvo).offset().top,
		h = $(window).height() / 3;

	$('body').animate({
		scrollTop: top - h},
		1000, function() {
		/* stuff to do after animation is complete */
	});
});